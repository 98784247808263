import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/css/reset.css'
import '@/assets/css/common.css'
import '@/assets/css/styles.css'
import '@/assets/css/trp-language-switcher.css'
import '@/assets/css/index.css'

import '@/assets/js/jquery.min.js'

createApp(App).use(router).mount('#app')
