<template>
  <div data-aos-easing="ease" data-aos-duration="400" data-aos-delay="0">
    <router-view />
    <FooterLayout />
  </div>
</template>

<script setup>
import FooterLayout from '@/components/footer.vue';

import { onMounted } from "vue";

onMounted(() => {
  AOS.init({
    // easing: 'ease-out-back',
    // Duration: 1000,
    // once:false,
    // offset:300, 
    // anchorPlacement: 'top-top',
  });
})
</script>

<style></style>