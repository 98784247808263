import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/index.vue'),
    meta: {
      title: 'Online Broker for US and Hong Kong Stock & Futures Trading-Doo Financial'
    }
  },
  {
    path: '/products/shares/',
    name: 'shares',
    component: () => import(/* webpackChunkName: "home" */ '../views/products/shares.vue'),
    meta: {
      title: 'U.S. Stocks Account | Hong Kong Stock Account | U.S. and Hong Kong Stocks Trading Platform - Doo Financial'
    }
  },
  {
    path: '/products/futures/',
    name: 'futures',
    component: () => import(/* webpackChunkName: "home" */ '../views/products/futures.vue'),
    meta: {
      title: 'Futures Account | Futures Investment | Futures Trading Platform - Doo Financial'
    }
  },
  {
    path: '/products/cfds/',
    name: 'cfds',
    component: () => import(/* webpackChunkName: "home" */ '../views/products/cfds.vue'),
    meta: {
      title: 'CFDs Account Opening | CFDs Trading | CFDs Trading Platform - Doo Financial'
    }
  },
  {
    path: '/trading-platform/intrade/',
    name: 'intrade',
    component: () => import(/* webpackChunkName: "home" */ '../views/trading-platform/intrade.vue'),
    meta: {
      title: 'InTrade | U.S. & Hong Kong Stocks Trading Software | Futures Trading Software - Doo Financial'
    }
  },
  {
    path: '/trading-platform/tradingview/',
    name: 'tradingview',
    component: () => import(/* webpackChunkName: "home" */ '../views/trading-platform/tradingview.vue'),
    meta: {
      title: 'TradingView | U.S. & Hong Kong Stocks Trading Community | U.S. and Hong Kong Stocks Trading Software - Doo Financial'
    }
  },
  {
    path: '/trading-platform/metatrader4/',
    name: 'metatrader4',
    component: () => import(/* webpackChunkName: "home" */ '../views/trading-platform/metatrader4.vue'),
    meta: {
      title: 'MetaTrader4 | Forex Trading Software Download | Forex Trading Platform - Doo Financial'
    }
  },
  {
    path: '/trading-platform/metatrader5/',
    name: 'metatrader5',
    component: () => import(/* webpackChunkName: "home" */ '../views/trading-platform/metatrader5.vue'),
    meta: {
      title: 'MetaTrader5 | Forex Trading Software Download | Forex Trading Platform - Doo Financial'
    }
  },
  {
    path: '/trading-tools/vps/',
    name: 'vps',
    component: () => import(/* webpackChunkName: "home" */ '../views/trading-tools/vps.vue'),
    meta: {
      title: 'VPS Transaction | VPS Hosting | Free VPS Hosting - Doo Financial'
    }
  },
  {
    path: '/trading/pricing/',
    name: 'pricing',
    component: () => import(/* webpackChunkName: "home" */ '../views/trading/pricing.vue'),
    meta: {
      title: 'Trading Commission | Services & Fees | U.S. & Hong Kong Stock Trading - Doo Financial'
    }
  },
  {
    path: '/trading/funding/',
    name: 'funding',
    component: () => import(/* webpackChunkName: "home" */ '../views/trading/funding.vue'),
    meta: {
      title: 'Trading Commission | Services & Fees | U.S. & Hong Kong Stock Trading - Doo Financial'
    }
  },
  {
    path: '/partnership/exchange/',
    name: 'exchange',
    component: () => import(/* webpackChunkName: "home" */ '../views/partnership/exchange.vue'),
    meta: {
      title: 'Cooperative Exchange | Cooperative Stock Exchange | Cooperative Futures Exchange - Doo Financial'
    }
  },
  {
    path: '/aboutus/',
    name: 'aboutus',
    component: () => import(/* webpackChunkName: "home" */ '../views/aboutus.vue'),
    meta: {
      title: 'About Us | Company Profile | U.S and Hong Kong Stock Exchange - Doo Financial'
    }
  },
  {
    path: '/contactus/',
    name: 'contactus',
    component: () => import(/* webpackChunkName: "home" */ '../views/contactus.vue'),
    meta: {
      title: 'Contact Us | Contact Us | U.S. and Hong Kong Stock Exchange - Doo Financial'
    }
  },
  // {
  //   path: '/state',
  //   name: 'state',
  //   children: [
  //     {
  //       path: 'alabama',
  //       name: 'alabama',
  //       component: () => import(/* webpackChunkName: "alabama" */ '../views/state/alabama.vue'),
  //       meta:{
  //         title: 'Alabama - American Investment Council'
  //       }
  //     },
  //   ]
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  next()
})

export default router
