<template>
  <div class="footer-top">
    <div class="container">
      <div class="left">
        <div class="title">Start Your Global Investment Journey</div>
        <div class="des">
          <span>Register</span>
          <img
            src="https://www.doofinancial.com/wp-content/themes/df2021/style/images/home-page/icon/0.other/arrow-right.svg"
            alt="">
          <span>Verify</span>
          <img
            src="https://www.doofinancial.com/wp-content/themes/df2021/style/images/home-page/icon/0.other/arrow-right.svg"
            alt="">
          <span>Deposit</span>
          <img
            src="https://www.doofinancial.com/wp-content/themes/df2021/style/images/home-page/icon/0.other/arrow-right.svg"
            alt="">
          <span>Trading</span>
        </div>
      </div>
      <div class="right">
        <p class="alltip">Start Your Global Investment Journey</p>
        <p class="register"><a class="register"
            href="https://www.xadpei.com/#/pages/auth/register">Register</a>
        </p>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="container">
      <div class="footer-logo">
        <img src="https://www.doofinancial.com/wp-content/themes/df2021/style/images/home-page/icon/1.logo/whitte.svg"
          alt="" width="196" height="46">
      </div>
      <div class="footer-nav">
        <ul id="menu-footer-menu" class="ul_foot_bottom_nav ">
          <li id="menu-item-23"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-23 nav-item">
            <a title="Products" href="javascript:void(0)" class="nav-link">Products</a>
            <ul class="dropdown-menu" role="menu">
              <li id="menu-item-26"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-26 nav-item"><a
                  title="Securities" href="/products/shares/" class="dropdown-item">Securities</a></li>
              <li id="menu-item-25"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-25 nav-item"><a
                  title="Futures" href="/products/futures/" class="dropdown-item">Futures</a></li>
              <li id="menu-item-581"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-581 nav-item"><a title="CFDs"
                  href="/products/cfds/" class="dropdown-item">CFDs</a></li>
            </ul>
          </li>
          <li id="menu-item-27"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-27 nav-item">
            <a title="Tools" href="javascript:void(0)" class="nav-link">Tools</a>
            <ul class="dropdown-menu" role="menu">
              <li id="menu-item-28"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-28 nav-item">
                <a title="Trading platform" href="#?" class="dropdown-item">Trading platform</a>
                <ul class="dropdown-menu" role="menu">
                  <li id="menu-item-278"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-278 nav-item"><a
                      title="InTrade" href="/trading-platform/intrade/" class="dropdown-item">InTrade</a></li>
                  <li id="menu-item-338"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-338 nav-item"><a
                      title="TradingView" href="/trading-platform/tradingview/" class="dropdown-item">TradingView</a>
                  </li>
                  <li id="menu-item-337"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-337 nav-item"><a
                      title="MetaTrader 4" href="/trading-platform/metatrader4/" class="dropdown-item">MetaTrader
                      4</a></li>
                  <li id="menu-item-336"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-336 nav-item"><a
                      title="MetaTrader 5" href="/trading-platform/metatrader5/" class="dropdown-item">MetaTrader
                      5</a></li>
                </ul>
              </li>
              <li id="menu-item-29"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-29 nav-item">
                <a title="Trading Tools" href="#?" class="dropdown-item">Trading Tools</a>
                <ul class="dropdown-menu" role="menu">
                  <li id="menu-item-501"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-501 nav-item"><a
                      title="VPS" href="/trading-tools/vps/" class="dropdown-item">VPS</a></li>
                </ul>
              </li>
            </ul>
          </li>
          <li id="menu-item-35"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-35 nav-item">
            <a title="Trading" href="javascript:void(0)" class="nav-link">Trading</a>
            <ul class="dropdown-menu" role="menu">
              <li id="menu-item-36"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-36 nav-item">
                <a title="Pricing Overview" href="#?" class="dropdown-item">Pricing Overview</a>
                <ul class="dropdown-menu" role="menu">
                  <li id="menu-item-502"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-502 nav-item"><a
                      title="Commission" href="/trading/pricing/" class="dropdown-item">Commission</a></li>
                  <li id="menu-item-38"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-38 nav-item"><a
                      title="General Fee" href="#?" class="dropdown-item">General Fee</a></li>
                  <li id="menu-item-39"
                    class="menu-item menu-item-type-custom menu-item-object-custom menu-item-39 nav-item"><a
                      title="Margin Rate" href="#?" class="dropdown-item">Margin Rate</a></li>
                </ul>
              </li>
              <li id="menu-item-40"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-40 nav-item">
                <a title="Trading Service" href="#?" class="dropdown-item">Trading Service</a>
                <ul class="dropdown-menu" role="menu">
                  <li id="menu-item-452"
                    class="menu-item menu-item-type-post_type menu-item-object-page menu-item-452 nav-item"><a
                      title="Funding" href="/trading/funding/" class="dropdown-item">Funding</a></li>
                </ul>
              </li>
            </ul>
          </li>
          <li id="menu-item-41"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-41 nav-item">
            <a title="Partnership" href="javascript:void(0)" class="nav-link">Partnership</a>
            <ul class="dropdown-menu" role="menu">
              <li id="menu-item-453"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-453 nav-item"><a
                  title="Exchange Partners" href="/partnership/exchange/" class="dropdown-item">Exchange Partners</a>
              </li>
              <li id="menu-item-43"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-43 nav-item"><a
                  title="Introducing Broker" href="#?" class="dropdown-item">Introducing Broker</a></li>
              <li id="menu-item-44"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-44 nav-item"><a
                  title="White Label Partner" href="#?" class="dropdown-item">White Label Partner</a></li>
            </ul>
          </li>
          <li id="menu-item-45"
            class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-45 nav-item">
            <a title="About Us" href="javascript:void(0)" class="nav-link">About Us</a>
            <ul class="dropdown-menu" role="menu">
              <li id="menu-item-451"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-451 nav-item"><a
                  title="Our Story" href="/aboutus/" class="dropdown-item">Our Story</a></li>
              <li id="menu-item-48"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-48 nav-item"><a
                  title="Privacy Policy"
                  href="https://www.doofinancial.com/wp-content/uploads/2021/08/Privacy_Policy.pdf"
                  class="dropdown-item">Privacy Policy</a></li>
              <li id="menu-item-49"
                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-49 nav-item"><a
                  title="Terms Of Service"
                  href="https://www.doofinancial.com/wp-content/uploads/2021/08/Terms_Of_Use.pdf"
                  class="dropdown-item">Terms Of Service</a></li>
              <li id="menu-item-450"
                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-450 nav-item"><a
                  title="Contact Us" href="/contactus/" class="dropdown-item">Contact Us</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="foot-con">
        <ul class="con_ul">
          <li>
            <div class="title">
              Doo Entities Notice
            </div>
            <div class="con">
              <div class="row">
                <div class="left">
                  <span class="con_title">Elish &amp; Elish Inc</span>
                  <div class="con_des">
                    <p>It is a company with the registered office address at 5049 Edwards Ranch Road, Suite 400, Fort
                      Worth, TX 76109, United States.</p>
                    <p>Elish &amp; Elish Inc is a broker-dealer, authorized and regulated by the United States
                      Securities and Exchange Commission (US SEC) and Financial Industry Regulatory Authority (US
                      FINRA) with the regulation number SEC: 8-41551 and CRD: 24409 respectively.</p>
                    <p>Peter Elish Investments Securities is an authorised trading name by Elish &amp; Elish Inc.</p>
                  </div>
                </div>
                <div class="right">
                  <span class="con_title">Doo Clearing Limited</span>
                  <div class="con_des">
                    <p>It is a company with the registered office address at Berkeley Suite 35 Berkeley Square,
                      Mayfair, London, England, W1J 5BF, and the company registration number is 10684079.</p>
                    <p>Doo Clearing Limited is a futures and forex broker and liquidity provider, authorized and
                      regulated by the United Kingdom Financial Conduct Authority (UK FCA), and the regulatory number
                      is 833414.</p>
                  </div>
                </div>
                <div class="clear"></div>
              </div>
              <div class="row">
                <div class="left">
                  <span class="con_title">Drip Australia Limited</span>
                  <div class="con_des">
                    <p>It is a company with the registered office address at 'Tower 1' Suite 1302 Level 13, 475
                      Victoria Avenue, Chatswood NSW 2067, Australia, and the Australian Company Number is 100 139 20.
                    </p>
                    <p>Drip Australia Limited is an Australian financial services licensee, authorized and
                      regulated by the Australian Securities &amp; Investments Commission (ASIC), and the regulatory
                      number is 222650.</p>
                  </div>
                </div>
                <div class="right">
                  <span class="con_title">Doo Exchange AU Pty Ltd</span>
                  <div class="con_des">
                    <p>It is a company with the registered office address at 'Tower 1' Suite 1302 Level 13, 475
                      Victoria Avenue, Chatswood NSW 2067, Australia, and the Australian Company Number is 661 717
                      237.</p>
                    <p>Doo Exchange AU Pty Ltd is a Currency Exchange and Remittance service provider, authorized and
                      regulated by the Australian Transaction Reports and Analysis Centre (AUSTRAC), and the
                      authorizations number are IND100812107-001 and DCE100812107-001 respectively.</p>
                  </div>
                </div>
                <div class="clear"></div>
              </div>
              <div class="row">
                <div class="left">
                  <span class="con_title">Drip HK Limited</span>
                  <div class="con_des">
                    <p>It is a company with the registered office address at Unit A-1, 25/F, Tower B, Billion Centre,
                      No.1 Wang Kwong Road, Kowloon Bay, Hong Kong, and the company registration number is 2099737.
                    </p>
                    <p>Drip HK Limited is licensed for Type 4 (advising on securities) and Type 9 (asset
                      management) activities, regulated by the Securities and Futures Commission of Hong Kong, and the
                      Central Entity number is BSM562.</p>
                  </div>
                </div>
                <div class="right">
                  <span class="con_title">Doo Wealth Management HK Limited</span>
                  <div class="con_des">
                    <p>It is a company with the registered office address at Unit E, 30/F, Tower A, Billion Centre,
                      No.1 Wang Kwong Road, Kowloon Bay, Kowloon, Hong Kong, and the company registration number is
                      2507030.</p>
                    <p>Doo Wealth Management HK Limited is a licensed insurance broker company, authorized and
                      regulated by the Hong Kong Insurance Authority (Insurance Broker License), and the regulatory
                      number is FB1823.</p>
                  </div>
                </div>
                <div class="clear"></div>
              </div>
              <div class="row">
                <div class="left">
                  <span class="con_title">Doo Holding Group Limited</span>
                  <div class="con_des">
                    <p>It is a company with the registered office address at Unit A, 25/F, Tower B, Billion Centre,
                      No.1 Wang Kwong Road, Kowloon Bay, Hong Kong, and the company registration number is 2169884.
                    </p>
                    <p>Doo Holding Group Limited is a licensed money service operator, authorized and regulated by the
                      Hong Kong Customs and Excise Department (Money Service Operator License), and regulatory number
                      is 18-06-02444.</p>
                  </div>
                </div>
                <div class="right">
                  <span class="con_title">Doo Properties HK Limited</span>
                  <div class="con_des">
                    <p>It is a company with the registered office address at Unit E, 30 Floor, Tower A, Billion
                      Centre, No.1 Wang Kwong Road, Kowloon Bay, Kowloon, Hong Kong, and the company registration
                      number is 3106018.</p>
                    <p>Doo Properties HK Limited is a real estate agency, regulated by the Hong Kong Estate Agents
                      Authority, and the license number is C-094694.</p>
                  </div>
                </div>
                <div class="clear"></div>
              </div>
              <div class="row">
                <div class="left">
                  <span class="con_title">Doo Consulting Limited</span>
                  <div class="con_des">
                    <p>It is a company with the registered office address at Unit A, 25/F, Tower B, Billion Centre,
                      No.1 Wang Kwong Road, Kowloon Bay, Hong Kong, and the company registration number is 2601567.
                    </p>
                    <p>Doo Consulting Limited is a licensed company service provider, authorized and regulated by the
                      Hong Kong Companies Registry (HK CR), and the regulatory number is TC006709.</p>
                  </div>
                </div>
                <div class="right">
                </div>
                <div class="clear"></div>
              </div>
            </div>
          </li>
          <li>
            <div class="title">
              Risk Disclosure
            </div>
            <div class="con">
              <div class="con_des">
                <p>Online trading of securities, futures, currencies, foreign equities, CFDs and other financial
                  instruments involves substantial risk of loss due to unpredictable market movements, and can result
                  in substantial losses exceeding your initial investment. Past performance of an investment is not an
                  indicator of its performance in the future. You should consider the risks involved in trading any
                  financial instrument to ensure that you fully understand the same and may do so by seeking
                  independent financial advice before you decide to trade. Any trading symbols displayed herein are
                  for illustrative purposes only and shall not constitute any advice or recommendation by us. Doo
                  Financial shall bear no liability to you for any direct, indirect, special, consequential or
                  incidental loss and/or damage arising from your transactions.</p>
              </div>
            </div>
          </li>
          <li>
            <div class="title">
              Brand and Entity Notice
            </div>
            <div class="con">
              <div class="con_des">
                <p>Drip is a brand name and intellectual property of Doo Group. Doo Group reserves all rights
                  for Doo and its affiliated brands, including without limitation to Doo Tech, Doo Prime, Doo
                  Consulting, Doo Clearing, Peter Elish, etc.</p>
                <p>We have a variety of regulated and licensed entities in different jurisdictions to provide a
                  variety of financial products and services. You can engage in business transactions with any of our
                  entities, subject to applicable laws and regulations. Please bear in mind that the entity chosen
                  shall mean that your trading account, trading activities and funds will be governed and regulated by
                  the respective regulatory authority of the jurisdiction whereupon the entity is located.</p>
                <p>We either:
                </p>
                <div class="height8"></div>
                (i) offer limited access through the applicable and relevant entity; or<div class="height8"></div>
                (ii) do not offer our services; <div class="height8"></div>
                to individuals or companies of certain jurisdictions, including but not limited to Afghanistan,
                Canada, Congo (Kinshasa), Cuba, Indonesia, Iran, North Korea, Singapore, Sudan, Syria, United States
                and Yemen. The information and services on our website are not applicable and shall not be provided to
                a country or jurisdiction where such distribution of information and services is contrary to the
                respective local statutes and regulations. Visitors from the above regions should confirm whether your
                decision to invest in our services is in accordance with the statutes and regulations of your country
                or jurisdiction before you use our services. We reserve our rights to vary, amend or discontinue our
                products and services at any time.
                <p></p>
              </div>
            </div>
          </li>
          <li>
            <div class="title">
              Website Terms and Conditions
            </div>
            <div class="con">
              <div class="con_des">
                <p>This website is owned and operated by Doo Technology Singapore Pte. Ltd. (Company Registration
                  Number 202104581D). By accessing and/or using this website, you agree to be bound by any terms and
                  conditions, statements, disclaimers and notices contained in this website. You must be at least 18
                  years old to access and/or use our website, products and services. By accessing and/or using this
                  website, you confirm that you are an individual of full age and capacity in compliance with the
                  local laws and regulations of your country. </p>
                <p>The nature of the information provided on this website is general wherein your or your client's
                  objectives, financial needs or circumstances have not been taken into account.</p>
                <p>The information provided on this website together with the products and services offered on this
                  website are not intended to be provided to any person or entity in any country or jurisdiction where
                  such provision is contrary to local laws and/or regulations. </p>
                <p>Any comment, statement or data provided, material or third party material (“Material”) provided on
                  this website is solely for illustrative purpose only. The Material shall neither be specific to any
                  person or organisation with publicity, promotion, and/or marketing activities, and does not contain,
                  and should not be construed as business advice, tax advice, trading advice, legal or regulatory
                  advice, investment advice, investment recommendation, any form of commitment, and/or offer for any
                  transactions. While we have taken all reasonable efforts to ensure the accuracy and completeness of
                  the information provided, we make no representations nor warranties to the Material and shall not be
                  liable for any loss, including but not limited to loss of profit, direct or indirect loss or damages
                  for any inaccuracies and/or incompleteness from the information provided. You shall only use the
                  Material for personal use and shall not reproduce, copy, redistribute and/or license the Material
                  without our consent. </p>
                <p>We may modify this website from time to time without prior notice to you and your continued access
                  and/or use of this website shall represent your acceptance of the modifications. </p>
                <p>We comply with all applicable laws and regulations regarding the use of your personal information,
                  for more information, please see our Privacy Policy. </p>
                <p>We use cookies on our websites to customize the information and experience displayed on our website
                  according to your preferences. By accessing this website, you acknowledge that you have read and
                  agree to the details above and agree to our use of cookies. For more information on our use of
                  cookies, please see our Cookie Policy. </p>
                <p>We strictly comply with all applicable laws and regulations of the relevant jurisdictions where our
                  entities operate. It is your responsibility to determine and ensure that your investment meets the
                  requirements of your local jurisdiction. You undertake to bear all the consequences of your
                  investment and trading activities. </p>
                <p>If you have any questions or comments, or if you have a concern about the way in which we provide
                  services in certain jurisdictions. You may also contact us by email.</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="footer-copyright">
        <div class="left">* Asset insurance is only applicable to customer accounts at Peter Elish Investments
          Securities.</div>
      </div>
    </div>
    <div class="footer-end">
      <div class="container">
        This website is owned and operated by Drip Holding Pte. Ltd. (Company No. 201930035D)<span
          style="width: 8px;display: inline-block;"></span>© 2021 Drip . All Rights Reserved .
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {

  // $(document).ready(function () {
  //   var width = $(window).width();
  //   if (width < 1024) {
  //     $('.foot-con .title').click(function () {
  //       $(this).parent().siblings().find('.con').slideUp(600);
  //       $(this).parent().siblings().find('.title').removeClass('act').addClass('clearcon');
  //       $(this).removeClass('clearcon').toggleClass('act').next().slideToggle(600); //
  //       $(this).parents('.container').find('.ul_foot_bottom_nav>li.on').removeClass('on').next('.dropdown-menu').slideUp(600);
  //       $(this).parents('.container').find('.ul_foot_bottom_nav>li').find('.dropdown-menu').slideUp(600);
  //     });
  //   } else {
  //     $('.foot-con .title').click(function () {
  //       $(this).parent().siblings().find('.con').slideUp(600);
  //       $(this).parent().siblings().find('.title').removeClass('act').addClass('clearcon');;
  //       $(this).removeClass('clearcon').toggleClass('act').next().slideToggle(600); //
  //     });
  //   }
  //   $(".head_top_nav_wap .ul_head_top_nav>li:not(:first-child)>a").attr('href', 'javascript:void(0)');
  // });

})
</script>